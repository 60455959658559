import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ApiResponse, Tag, TagResponse } from '@shared/types';
import { LocalizeService } from '@trendency/ng-translate';
import { catchError, map, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TagsService {
  readonly #http: HttpClient = inject(HttpClient);
  readonly #localizeService = inject(LocalizeService);

  readonly tagsWithRoute = (tags: readonly TagResponse[], baseRoute: string[]): Tag[] =>
    tags.map((tag) => ({
      ...tag,
      url: this.#localizeService.translateRouteToCurrentLanguage(baseRoute.concat(tag.slug)),
    }));

  getTags$(): Observable<ReadonlyArray<Tag>> {
    return this.#http.get<ApiResponse<ReadonlyArray<TagResponse>>>('system/tags').pipe(
      map((res: ApiResponse<ReadonlyArray<TagResponse>>) => this.tagsWithRoute(res.data, [])),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      })
    );
  }
}
