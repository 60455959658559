import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ROUTES_FULL_PATHS } from '@core/routes/app.route-config.token';
import { TagsService } from '@core/services/tags/tags.service';
import { defaultArticlesPerPage } from '@shared/constants';
import {
  ApiResponse,
  ArticleCard,
  ArticleCardResponse,
  NewsArticle,
  NewsArticleResponse,
  PaginationParams,
  Tag,
  TagResponse,
} from '@shared/types';
import { LocalizeService } from '@trendency/ng-translate';
import { ContentSeoService } from '@trendency/simple/page';
import { ContentPageMeta } from '@trendency/simple/page/types/content-page.types';
import { map, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  readonly #http = inject(HttpClient);
  readonly #seoService = inject(ContentSeoService);
  readonly #tagsService = inject(TagsService);
  readonly #localizeService = inject(LocalizeService);
  readonly #routesFullPaths = inject(ROUTES_FULL_PATHS);

  readonly articlesWithRoute = (articles: readonly ArticleCardResponse[]): ArticleCard[] =>
    articles.map((article) => ({
      ...article,
      tags: this.#tagsService.tagsWithRoute(article.tags, this.#routesFullPaths.NEWS_TAG),
      url: this.#localizeService.translateRouteToCurrentLanguage(this.#routesFullPaths.NEWS_ARTICLE.concat(article.slug)),
    }));

  getFeaturedNews(tags?: readonly string[], limit?: number): Observable<readonly ArticleCard[]> {
    let params = new HttpParams();
    if (tags) params = params.append('filter', JSON.stringify({ tags }));
    if (limit) params = params.append('limit', limit);

    return this.#http
      .get<ApiResponse<readonly ArticleCardResponse[]>>('/magazine/fresh-news', { params })
      .pipe(map((res) => this.articlesWithRoute(res.data)));
  }

  getNewsArticle(slug: string): Observable<NewsArticle> {
    return this.#http.get<ApiResponse<NewsArticleResponse>>(`/magazine/articles/${slug}`).pipe(
      tap((res) => this.#seoService.setMeta(res.data.meta as ContentPageMeta, res.data.meta?.title)),
      map((res) => ({
        ...res.data,
        content: res.data.content.map((block) => ({
          type: block.template,
          data: block,
        })),
      }))
    );
  }

  getNewsList(pagination: PaginationParams = { page: 1, itemsPerPage: defaultArticlesPerPage }): Observable<readonly ArticleCard[]> {
    const params = new HttpParams().append('pagination', JSON.stringify(pagination));

    return this.#http
      .get<ApiResponse<readonly ArticleCardResponse[]>>('/magazine/articles', { params })
      .pipe(map((res) => this.articlesWithRoute(res.data)));
  }

  getTagNewsList(tag: string, pagination: PaginationParams): Observable<readonly ArticleCard[]> {
    const params = new HttpParams().append('filter', JSON.stringify({ tags: [tag] })).append('pagination', JSON.stringify(pagination));
    return this.#http
      .get<ApiResponse<readonly ArticleCardResponse[]>>('/magazine/articles', { params })
      .pipe(map((res) => this.articlesWithRoute(res.data)));
  }

  getNewsTags(): Observable<readonly Tag[]> {
    return this.#http
      .get<ApiResponse<readonly TagResponse[]>>('/system/tags')
      .pipe(map((res) => this.#tagsService.tagsWithRoute(res.data, this.#routesFullPaths.NEWS_TAG)));
  }
}
